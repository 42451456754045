<template>
  <div>
    <div class="float-right p-2">
      <button
        :class="isActive == 'week' ? 'btn-primary' : 'btn-default'"
        class="btn mr-2"
        type="button"
        @click="activeState('week')"
      >
        Last 7 Days
      </button>
      <button
        class="btn mr-2"
        :class="isActive == 'month' ? 'btn-primary' : 'btn-default'"
        type="button"
        @click="activeState('month')"
      >
        Month
      </button>
      <button
        class="btn"
        :class="isActive == 'year' ? 'btn-primary' : 'btn-default'"
        type="button"
        @click="activeState('year')"
      >
        Year
      </button>
    </div>
    <CChartBar :datasets="newChartData" :labels="chartLabels" :options="{ scales:{ yAxes: [{ticks: { beginAtZero: true } }] } }" />
  </div>
</template>

<script>
import { CChartBar } from "@coreui/vue-chartjs";
import { mapGetters } from "vuex";

export default {
  name: "ConsumptionBarChart",
  components: { CChartBar },
  data() {
    return {
      labelArray: [],
      chartDataArray: [],
      isActive: "week",
    };
  },
  computed: {
    ...mapGetters("Dashboard", ["newChartData", "chartLabels"]),
  },

  methods: {
    activeState(state) {
      this.isActive = state;
      this.$store.dispatch("Dashboard/getDateWiseMeterConsumptionsData", state);
    },
  },
  mounted() {
    this.$store.dispatch("Dashboard/getDateWiseMeterConsumptionsData");
  },
};
</script>


